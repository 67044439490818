<template>
  <div>
    <div class="box-search" style="float: right;padding-right:19px">
      <el-button type="primary" class="addBtn" @click="addBtn()"
        >添加管理员</el-button
      >
    </div>
    <el-table
      :data="TabData"
      style="width: 100%"
      max-height="750"
      :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
    >
      <el-table-column prop="nickname" label="姓名">
        <template slot-scope="scope">
          <div >{{ scope.row.nickname }}
             <el-tag size="medium" class="bg-color-blue font-size-12"  v-if="scope.row.identity ==  Constant.identity.enterpriseAdmin " >创建人</el-tag>
          </div>
          <div style="margin-top:.5rem">{{ scope.row.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="department_name" label="部门"> </el-table-column>
      <el-table-column prop="identity_cn" label="权限"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            v-if="isShowEditBtn(scope.row)"
            @click.native.prevent="editRow(scope.row)"
            type="text"
            size="small"
            style="color: #3468FE;"
          >
            编辑
          </el-button>
          <el-button
            v-if="isShowDelBtn(scope.row)"
            @click.native.prevent="deleteRow(scope.row)"
            type="text"
            size="small"
            style="color:red"
          >
            删除
          </el-button>
          <el-button
            v-if="isShowTransferBtn(scope.row)"
            @click.native.prevent="transferRow(scope.row)"
            type="text"
            size="small"
            style="color: #3468FE;"
          >
            转让
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog @close="closeDialog('memberEditValidateFrom')" width="40%" :title="addEditForm" :visible.sync="editFormVisible">
      <el-form :model="editForm" ref="memberEditValidateFrom">
        <el-form-item label="选择成员" prop="user_id" :label-width="formLabelWidth" class="select-Form"
        :rules="[{required: true,message: '请选择成员',trigger: 'blur'}]">
          <el-select filterable :disabled="addEditForm=='编辑管理员'" class="select-number" v-model="editForm.user_id" placeholder="请选择成员">
            <el-option
              v-for="item in memberList"
              :key="item.user_id"
              :label="item.nickname"
              :value="item.user_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="identity"  :label-width="formLabelWidth">
          <el-radio-group v-model="editForm.identity">
            <el-radio  :label="1">超级管理员</el-radio>
            <el-radio  :label="2">普通管理员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="editForm.identity==1" label="分配权限: " :label-width="formLabelWidth">
          <div >
            全部权限
          </div>
        </el-form-item>
        <el-form-item v-else label="分配权限: " :key="validateProp" :prop="validateProp" :label-width="formLabelWidth"
        :rules="{ required: true, message: '请选择权限', trigger: 'blur' }">
          <el-cascader
            v-model="editForm.orgName"
            style="max-width:500px !important;"
            :options="userlist"
            :props="props"
            :key="cascaderKey"
            clearable
            :show-all-levels="false"
            @change="handleChange"
          ></el-cascader>
          
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">取 消</el-button>
        <el-button type="primary" class="buttonColor"  @click="editFormSure('memberEditValidateFrom')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 转让 -->
    <el-dialog width="40%" title="转让账号" :visible.sync="transferFormVisible" >
      <el-form :model="transferForm" ref="transferValidateForm" :rules="rules">
        <el-form-item>
          <div class="line">换绑主管理账号，需要验证当前主管理账号的身份。</div>
        </el-form-item>
        <el-form-item label="选择成员: "  prop="user_id" :label-width="formLabelWidth">
          <el-select v-model="transferForm.user_id" filterable placeholder="请输入姓名或手机号">
            <el-option
              v-for="item in memberList"
              :key="item.user_id"
              :label="item.nickname + '-' + item.phone"
              :disabled = "item.user_id==current_uId"
              :value="item.user_id"
            ></el-option>
          </el-select>

<!--          <el-autocomplete-->
<!--              ref="curMember"-->
<!--              class="inline-input"-->
<!--              v-model="transferForm.nickname"-->
<!--              :fetch-suggestions="querySearch"-->
<!--              clearable-->
<!--              prop="nickname"-->
<!--              placeholder="请输入姓名或手机号"-->
<!--              :trigger-on-focus="false"-->
<!--              @select="handleSelect"-->
<!--          >-->
<!--            <i class="el-icon-search" slot="prefix"></i>-->
<!--          </el-autocomplete>-->
        </el-form-item>
        <el-form-item label="当前创建人手机号: " :label-width="formLabelWidth">
          <el-input
            class="search-input-dialog"
            type="text"
            placeholder="请输入姓名或手机号"
            maxlength="10"
            v-model="transferForm.phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码: " prop="code" :label-width="formLabelWidth">
          <div class="flex-row">
            <el-input
              class="search-input-code"
              type="text"
              placeholder="请输入验证码"
              maxlength="10"
              v-model="transferForm.code"
              autocomplete="off"
            ></el-input>

            <CodeButton style="height:2.5rem;margin-left:.8rem"
              @sendCodeFun="sendCodeFun"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="transferFormVisible = false">取 消</el-button>
        <el-button type="primary" class="buttonColor" @click="transferFormSure">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog  width="40%" title="删除" :visible.sync="delFormVisible">
      <span class="del-content">确认删除该管理员？</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="delFormVisible = false">取 消</el-button>
        <el-button type="primary" class="buttonColor" @click="delSure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CodeButton from '@components/CodeButton'
import Constant from '@utils/constant.js'
import { isPhoneOrEmail } from '@utils/login.js'
import { deepClone } from '@/utils/utils'
export default {
  components: {
    CodeButton
  },
  props: {
    userlist: Array,
    memberListOrigin: Array,
    TabData: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    var validateId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择成员'))
      } else {
        callback()
      }
    }
    return {
      memberList: [],
      memberListCopy: [],
      transferMember: '',
      cascaderKey: 1,
      Constant: Constant,
      addEditForm: '',
      validateProp: 'orgName',
      props: { multiple: true },
      editForm: { 
        identity: 1,
        orgName:[]
      },
      editFormVisible: false,
      transferFormVisible:false,
      delFormVisible: false,
      formLabelWidth: '120px',
      transferForm: { nickname: '' },
      form: {},
      showModalTree: false,
      user_id_self: 1,
      rules: {
        // nickname: [
        //   {
        //     required: true,
        //     validator: validateId,
        //     trigger: 'change'
        //   }
        // ],
        user_id: [
          {
            required: true,
            message: '请选择成员',
            trigger: 'change'
          }
        ],
        identity: [
          {
            required: true,
            message: '请选择',
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed:{
    current_uId(){
      return  this.$store.state.user.userInfo.uId;
    }
  },
  watch: {
    memberListOrigin:{
      handler(cur){
        if(cur){
          this.memberList = JSON.parse(JSON.stringify(cur))
          this.memberListCopy = JSON.parse(JSON.stringify(cur))
        }

      },
      immediate: true
    },
    'editForm.identity': {
      handler (cur) {
        if (cur) {
          this.cascaderKey++
          // this.$emit('authChange', JSON.parse(JSON.stringify(cur)))
        }
      },
      deep: true
    },
    'transferForm.user_id': {
      handler (cur) {
        console.log(cur)
      },
      immediate: true,
      deep: true
    },
    'editForm.orgName': {
      handler (cur) {
        console.log('---orgName--',cur)
      },
      immediate: true,
      deep: true
    },
    editForm: {
      handler (cur) {
        console.log(cur)
      },
      immediate: true,
      deep: true
    }
  },
  created () {
  },
  methods: {
    dataFilter(val){
      console.log(val)
      if (val) { //val存在
        this.memberList = this.memberListCopy.filter((item) => {
          if (!!~item.nickname.indexOf(val) || !!~item.phone.indexOf(val) || !!~item.nickname.toUpperCase().indexOf(val.toUpperCase()) || !!~item.phone.toUpperCase().indexOf(val.toUpperCase())) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.memberList = JSON.parse(JSON.stringify(this.memberListCopy));
      }
    },
    querySearch (qs, cb) {
      var data = JSON.parse(JSON.stringify(this.memberList))
      var results = qs
        ? data
          .filter((res) => res.nickname.includes(qs) || res.phone.includes(qs))
          .map((res) => {
            res.value = res.nickname + '-' + res.phone
            return res
          })
        : []
      // 没匹配到内容则返回以下提示
      if (results.length === 0) {
        results[0] = {
          value: '暂无匹配内容'
        }
      }
      cb(results)
      if (this.$refs.curMember) this.$refs.curMember.activated = true
    },
    handleSelect (sele) {
      if (sele.value === '暂无匹配内容') {
        this.transferForm.nickname = ''
        return
      }
      this.transferForm.nickname = sele.nickname
      this.transferForm.user_id = sele.user_id
    },
    isShowTransferBtn (item) {
      return this.$store.state.user.userInfo.identity === Constant.identity.enterpriseAdmin &&
      item.identity === Constant.identity.enterpriseAdmin 
    },
    isShowEditBtn (item) {
      // 同级别权限不能编辑或删除对方，只能向下编辑 创建人 > 超级管理员 > 普通管理员
      //  :disabled="this.$store.state.user.userInfo.uId==user_id_self"
       if(item.identity===Constant.identity.superAdmin && this.$store.state.user.userInfo.uId === item.user_id){
        return true;
      }
      return this.$store.state.user.userInfo.uId !== item.user_id && (item.identity > this.$store.state.user.userInfo.identity)
    },
    isShowDelBtn (item) {
      if(item.identity===Constant.identity.superAdmin && this.$store.state.user.userInfo.uId === item.user_id){
        return true;
      }
      return this.$store.state.user.userInfo.uId !== item.user_id && (item.identity > this.$store.state.user.userInfo.identity)
    },
    deleteRow (row) {
      this.$emit('delBtnUp', row)
      this.delFormVisible = true
    },
    delSure () {
      this.$emit('delSureBtn')
      this.delFormVisible = false
    },
    transferFormSure () { // 提交转让按钮
      this.$refs.transferValidateForm.validate((valid) => {
        if (valid) {
          this.$emit('transferSure', this.transferForm)
        }
      })
    },
    transferRow (row) { // 开启转让弹框
      this.transferForm.nickname = ''
      if (this.transferForm.user_id) {
        delete this.transferForm.user_id
      }
      this.transferForm.old_user_id = row.user_id
      this.transferForm.phone = row.phone
      this.$emit('transferRowUp')
      this.transferFormVisible = true
    },
    editRow (row) {
      this.editForm = deepClone(row)
      var orgName = JSON.parse(JSON.stringify(row.auth_cn))
      this.user_id_self = this.editForm.user_id
      this.addEditForm = '编辑管理员'

      // 传给后端之前约定的type 1是超级管理员 2是普通管理员 ，
      this.editForm.identity = this.editForm.identity === 2 ? 1 : 2
      this.$emit('editBtnUp', this.addEditForm)
      this.editForm.orgName = []
      orgName.forEach(item => {
        var arr = []
        arr.push(item)
        if (item != 'home') {
          // if (item == 'statistics' || item == 'project' || item == 'shoot') {
            arr.unshift('')
          // } else {
            // arr.unshift(undefined)
          // }
        }
        this.editForm.orgName.push(arr)
      })
      this.editFormVisible = true
    
    },
    addBtn () { // 添加管理员
      this.editForm={ 
        identity: 1,
        orgName:[]
      };
      // 过滤掉已经是超级管理的用户
      this.addEditForm = '添加管理员'
      this.editFormVisible = true
      this.$emit('addAdmin', this.addEditForm)
      
      // this.$refs.memberEditValidateFrom.clearValidate()
    },
    closeDialog(formName){
       console.log(formName)
        this.$refs[formName].clearValidate()
    },
    sendCodeFun (callback) {
      const platform = isPhoneOrEmail(this.transferForm.phone)
      if (!this.transferForm.user_id) { return false }
      if (platform !== '') {
        this.transferForm.platform = platform
        // 发送验证码
        this.$emit('sendCodeFun', this.transferForm)
        callback()
      }
    },
    editFormSure () {
      this.$refs.memberEditValidateFrom.validate((valid) => {
        if (valid) {
          const params = {
            user_id: this.editForm.user_id,
            type: this.editForm.identity,
            auth: this.editForm.auth
          }
          this.$emit('editFormSure', params)
        }
      })
    },
    handleChange (value) {
      this.editForm.auth = []
      if (value !== undefined) {
        value.forEach((element) => {
          this.editForm.auth.push(element[element.length - 1])
        })
      }
      this.editForm.auth = this.editForm.auth.join(',')
    },
    hideDialog(){
      this.transferFormVisible = false;
      this.editFormVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/page.scss";
.el-select{
  .el-input--suffix{
    // width: 23.14rem;
  }
}
.bg-color-blue{
  border-color: #ffffff;
}

::v-deep .el-cascader-menu__wrap{
  height: 15rem;
}
</style>

