<template>
  <div>
    <el-breadcrumb class="breadcrumb-title" separator="/">
            <el-breadcrumb-item>企业设置</el-breadcrumb-item>
            <el-breadcrumb-item>管理员权限</el-breadcrumb-item>
        </el-breadcrumb>
    <el-card>
      <div class="box-search" style="float:left;margin-left:19px">
        <SearchInput
          class="search-input"
          placeholderStr="请输入姓名或手机号"
          @updateSearchList="updateSearchList"
        />
      </div>
      <template v-if="tableData != undefined">
        <Table
          class="table"
          ref = 'refJurisdictionTable'
          :TabData="tableData"
          @editBtnUp="editBtnUp"
          @addAdmin="addAdmin"
          @transferRowUp="transferRowUp"
          @transferBtnUp="transferBtnUp"
          @delBtnUp="delBtnUp"
          @delSureBtn="delSureBtn"
          @editFormSure="editFormSure"
          @transferSure="transferSure"
          @sendCodeFun="sendCodeFun"
          @authChange="authChange"
          :userlist="userlist"
          :memberListOrigin="memberListOrigin"
        ></Table>
      </template>
      <el-pagination
       class="pagination"
        @current-change="handleCurrentChange"
        :current-page="curPage"
        :page-size="pageSize"
        :page-sizes="[pageSize]"
        layout="prev, pager, next, sizes, jumper , total"
        background
        :total="pageDate.length"
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import Table from './components/table.vue'
import SearchInput from '@components/SearchInput'
import request from '@request/api/index'
import Constant from '@utils/constant.js'

export default {
  components: {
    Table,
    SearchInput
  },
  data () {
    return {
      Constant: Constant,
      tableData: [],
      orgTableDate: [],
      addForm: {
        name: '',
        resource: ''
      },
      userlist: [],
      addFormVisible: false,
      editFormVisible: '',
      transferFormVisible: false,
      delFormVisible: false,
      curPage: 1,
      pageSize: 10,
      pageDate: [],
      user_id: '',
      searchInputText: '',
      memberListOrigin: []
    }
  },
  created () {
    this.getjurisdictList()
    this.getAlljurisdictList()
  },
  watch: {
    pageDate () {
      let page = this.pageDate.length / this.pageSize + 1
      if (this.pageDate.length % this.pageSize === 0) {
        page--
      }
      if (this.curPage > 1 && this.curPage > page) {
        this.curPage = page
      }
      this.computeList()
    }
  },
  methods: {
    authChange (data) {
      // var arr = []
      // if (data === '普通管理员') {
      //   console.log(this.userlist)
      //   this.userlist.forEach(item => {
      //     if (item.label === '企业设置') {
      //       var children = []
      //       for (var i = 0; i < item.children.length; i++) {
      //         if (item.children[i].label !== '管理员权限') {
      //           children.push(item.children[i])
      //         }
      //       }
      //       console.log(children)
      //       item.children = JSON.parse(JSON.stringify(children))
      //       arr.push(item)
      //     } else {
      //       arr.push(item)
      //     }
      //   })
      // } else {
      //   this.userlist.forEach(item => {
      //     if (item.label === '企业设置') {
      //       if (item.children.every((itm) => { return itm.label !== '管理员权限' })) {
      //         item.children.push({
      //           label: '管理员权限',
      //           value: 'auth'
      //         })
      //       }
      //       arr.push(item)
      //     } else {
      //       arr.push(item)
      //     }
      //   })
      // }
      // this.userlist = JSON.parse(JSON.stringify(arr))
    },
    computeList () {
      
      if(this.searchInputText){
        // this.curPage = 1
      }

      const start = (this.curPage - 1) * this.pageSize
      const end = start + this.pageSize
      this.tableData = this.pageDate.slice(start, end)
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.computeList()
    },
    filterData () {
      const copyList = JSON.parse(JSON.stringify(this.orgTableDate))
      
      const arr = []
      for (let i = 0; i < copyList.length; i++) {
        if (this.searchInputText !== '') {
          if (copyList[i].nickname.indexOf(this.searchInputText) >= 0 || copyList[i].phone.indexOf(this.searchInputText) >= 0) {
            arr.push(copyList[i])
          }
        } else {
          this.pageDate = this.orgTableDate
          return false
        }
      }
      this.pageDate = arr
    },
    updateSearchList (str) {
      this.searchInputText = str
      this.filterData()
      this.curPage = 1
    },
    async getjurisdictList () {
      const { data } = await request.enterprise.requestjurisdiction({})
      const resultData = data.data.filter((item, index, arr) => {
        // 创建人置顶
        if (item.identity === 1) {
          arr.splice(index, 1)
          arr.unshift(item)
        }
        return item
      })
      this.tableData = JSON.parse(JSON.stringify(resultData))
      this.orgTableDate = JSON.parse(JSON.stringify(resultData))
      this.pageDate = JSON.parse(JSON.stringify(resultData))
    },
    // 添加编辑
    async getAddEdit (params) {
      try {
        const getAddEditData = await request.enterprise.requestAddEdit(params)
        if (getAddEditData.data.code === 1000) {
          if (this.editFormVisible === '添加管理员') {
            this.$message.success('添加成功！')
          }
          else if (this.editFormVisible === '编辑管理员') {
            this.$message.success('编辑成功！')
          }
          
          this.$refs.refJurisdictionTable.hideDialog();
          this.getjurisdictList()
        }
      } catch (err) {
        console.log('err', err)
      }
    },
    // 权限列表
    async getAlljurisdictList () {
      await request.enterprise.requestjurisdictList({}).then((res) => {
        this.userlist = JSON.parse(JSON.stringify(res.data.data))
        var arr = []
        this.userlist.forEach(item => {
          if (item.label === '组织管理') {
            var children = []
            for (var i = 0; i < item.children.length; i++) {
              if (item.children[i].label !== '管理员权限') {
                children.push(item.children[i])
              }
            }
            item.children = JSON.parse(JSON.stringify(children))
            arr.push(item)
          } else if(item.label !=='费用中心'){ 
            arr.push(item)
          }
        })
        this.userlist = JSON.parse(JSON.stringify(arr))
      })
    },
    // 删除权限
    async getDeljurist (param) {
      const params = {
        user_id: param
      }
      await request.enterprise.requestDeljurist(params).then((res) => {
        if (res.data.code === 1000) {
          this.$message({
            center: true,
            message: '删除成功',
            type: 'success'
          })
          if(this.user_id===this.$store.state.user.userInfo.uId){
            this.$store.commit('removeToken')
            this.$router.push({path:'/login'})
            return
          }
          this.getjurisdictList()
        }
      })
    },
    // 搜索成员列表
    getMember () {
      const UserId = this.$store.state.user.userInfo.uId
      request.organization.repuestSeaarchMember({})
        .then((res) => {
          this.memberListOrigin = res.data.data.list
        })
    },
    transferRowUp () {
      this.getMember()
    },
    async transferSure (transferForm) {
          
      try {
        const params = {
          user_id: transferForm.user_id,
          old_user_id: transferForm.old_user_id,
          code: transferForm.code
        }
        const requestTransfer = await request.enterprise.requestTransferAccountNumber(params)
        if (requestTransfer.data.code === 1000) {
          this.$message.success('转让成功！')
          this.$refs.refJurisdictionTable.hideDialog();
        
          this.$store.commit('removeToken');
          this.$router.push({path:'/login'});
          
          this.getjurisdictList()
        }
      } catch (err) {

      }
    },
    editBtnUp (type, row) { // 编辑管理员
      this.getMember()
      // this.getAlljurisdictList()
      this.editFormVisible = type
      this.addForm = row
    },
    async addAdmin (type, row) { // 添加管理员
      const identity = this.Constant.identity
      await request.organization.repuestSeaarchMember({})
        .then((res) => {
          const memberListOrigin = res.data.data.list
          this.memberListOrigin = memberListOrigin.filter(item => {
            if (![identity.enterpriseAdmin, identity.superAdmin, identity.admin].includes(item.identity)) {
              return item
            }
          })
        })

      this.editFormVisible = type
      this.addForm = row
    },
    transferBtnUp (type, row) {
      this.transferFormVisible = type
    },
    delBtnUp (row) {
      this.user_id = row.user_id
    },
    delSureBtn () {
      this.getDeljurist(this.user_id)
      this.getjurisdictList()
    },
    editFormSure (params) {
      this.getAddEdit(params)
    },
    sendCodeFun (transferForm) {
      const phone = transferForm.phone
        ? transferForm.phone
        : transferForm.platform === 'phone'
          ? transferForm.phone
          : ''
      const email = ''
      request.login.requestCode({ phone: phone, email: email })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-card{
  position: relative;
  height: 980px;
}
.table{
  height: 20px;
}
</style>
